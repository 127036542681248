
import {
  quoteMapActions,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import { cloneDeep, get } from "lodash";
import Vue from "vue";
import RatingViewer from "./Components/RatingsViewer.vue";
import RatingViewerError from "./Components/RatingsViewerError.vue";
import RatingsViewerRetrying from "./Components/RatingsViewerRetrying.vue";
import RatingsViewerSkipped from "./Components/RatingsViewerSkipped.vue";
import QuoteSteps from "./QuoteSteps.vue";
import UnderwriterInfo from "./Components/UnderwriterInfo.vue";
import { validateNumberOfWeatherClaims } from "@/forms/utils";

export default Vue.extend({
  name: "rated-property-screen-component",
  components: {
    RatingsViewerSkipped,
    RatingViewer,
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    RatingSkeleton: () =>
      import("@/views/shared/quotes/Components/RatingSkeleton.vue"),
    RatingViewerError,
    RatingsViewerRetrying,
    QuoteSteps,
    UnderwriterInfo
  },
  props: {
    quoteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ratingId: "",
      loading: false,
      loadingText: "",
      needsApprovalCode: false
    };
  },
  async mounted() {
    this.$nextTick(async function() {
      this.ratingId = this.editing.ratingSelected || "";
      if (
        this.editing &&
        this.editing.ratings &&
        this.editing.ratings.length == 0
      ) {
        // this.editingField({ key: "status", value: "rating-in-progress" });
        this.loading = true;
        const response = await this.rateQuote({ quoteId: this.editing._id });
        this.setEdit(response.data);
        this.loading = false;
      }
    });
  },
  methods: {
    ...quoteMapActions([
      "selectRating",
      "rateQuote",
      "saveQuotePropertyFields"
    ]),
    ...quoteMapMutations({
      setEdit: "SET_EDIT",
      editingField: "SET_EDIT_FIELD",
      setItem: "SET_ITEM"
    }),
    ratingChangedHandler(ratingId: string) {
      this.ratingId = ratingId;
      this.saveSelectedRating();
    },
    async saveSelectedRating(key?: string) {
      if (key === "next") {
        if (this.needsApprovalCode) {
          await this.saveQuotePropertyFields({
            id: this.editing._id,
            payload: {
              hasUnderwriterApproved: this.editing.hasUnderwriterApproved,
              approvalCode: this.editing.approvalCode
            }
          });
        }
        return this.$router.push(
          `/quotes/${this.editing._id}/underwriting-qualifications`
        );
      }
      try {
        this.loading = true;
        this.loadingText = "Saving rate. Please wait...";
        const response = await this.selectRating({
          quoteId: this.editing._id,
          ratingId: this.ratingId
        });
        this.setEdit(response.data);
      } catch (error) {
        this.$appNotifyError(
          "There was an error saving your selection. Try again later."
        );
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    checkApprovalCode(value: boolean) {
      this.needsApprovalCode = value;
    }
  },
  computed: {
    ...quoteMapState(["editing", "makingApiRequest"]),
    disabledButton(): boolean {
      const approvalCode = get(this.editing, "approvalCode");
      return !approvalCode && this.needsApprovalCode;
    },
    nonWeatherApplianceCheck(): boolean {
      const {
        numberOfNonWeatherClaims = "",
        policyType = "",
        companyNumbers = []
      } = this.editing;
      return validateNumberOfWeatherClaims(
        numberOfNonWeatherClaims,
        policyType,
        companyNumbers
      );
    },
    ratingsSorted(): any {
      const ratings = cloneDeep(this.editing.ratings);
      return ratings.sort((x: any, y: any) => {
        if (x.status === "completed" && y.status === "completed") {
          return 0;
        } else if (x.status === "completed") {
          return -1;
        } else if (y.status === "completed") {
          return 1;
        }
        return 0;
      });
    },
    hasPendingRating(): boolean {
      if (this.editing && this.editing.ratings) {
        return this.editing.ratings.some(
          (rating: any) => rating.status === "in-progress"
        );
      }
      return false;
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    }
  }
});
