
//@click="stepClickHandler(step)"
//:key="step.id"
//v-for="(step, stepIdx) in steps"
import Vue from "vue";
import { IQuoteStepConfig } from "@/types/interfaces/shared";
export default Vue.extend({
  name: "",
  props: {
    step: {
      type: Object as Vue.PropType<IQuoteStepConfig>,
      required: true,
      default: () => []
    },
    currentStep: {
      type: Number,
      required: true,
      default: 1
    },
    stepIdx: {
      type: Number,
      required: true,
      default: 0
    },
    numberOfSteps: {
      type: Number,
      required: true,
      default: 0
    }
  }
});
