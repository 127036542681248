
import { quoteMapState } from "@/store/modules/quote";
import { get } from "lodash";
import Vue from "vue";
import QuoteStepItem from "./QuoteStepItem.vue";

export default Vue.extend({
  components: { QuoteStepItem },
  name: "quote-steps",
  props: {
    currentStep: {
      type: Number,
      required: true,
      default: 1
    },
    canClick: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data(): any {
    return {};
  },
  created() {},
  methods: {
    nextStep() {},
    prevStep() {},
    getValidationFor(validation: string): boolean {
      if (
        !this.canClick &&
        [
          "hasUnderwritingResponses",
          "hasCreditRating",
          "hasValidLossInformation"
        ].includes(validation)
      ) {
        return (
          this.canClick && get(this.editing, "hasValidUnderWritingResponses")
        );
      }
      if (this.editing && this.editing.ratingValidations) {
        return get(this.editing, `ratingValidations.${validation}`);
      }
      return false;
    },
    getStatusFor(validationKey: string): string {
      if (validationKey === "hasValidLossInformation") {
        return this.getValidationFor(validationKey) &&
          this.getValidationFor("hasCreditRating")
          ? "complete"
          : "upcoming";
      }
      return this.getValidationFor(validationKey) ? "complete" : "upcoming";
    },
    disableStep(step: { id: Number }): boolean {
      if (step.id === this.currentStep) return false;
      return this.step.status === "upcoming";
    },
    stepClickHandler(step: any) {
      const fieldsWithErrors = get(
        this.editing,
        "ratingValidations.fieldsWithErrors",
        []
      );
      if (this.canGoToStep(step) && !fieldsWithErrors.length) {
        this.$router.push(step.href); //.catch(() => {});
      }
    },
    canGoToStep(step: any): boolean {
      if (!step.key) return true;
      if (step.status === "complete") return true;
      if (this.getValidationFor(step.key)) return true;
      return false;
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    quoteId(): string {
      return this.$route.params.quoteId;
    },
    steps(): any[] {
      const steps = [
        //TODO decide whether to include and delete or uncomment
        // {
        //   id: 1,
        //   name: "Risk Address",
        //   description: "Specify risk address and related details",
        //   href: `/quotes/${this.quoteId}/risk-address`,
        //   status: this.getStatusFor("hasValidRiskAddress")
        // },
        // {
        //   id: 2,
        //   name: "Territory",
        //   description: "Select territory",
        //   href: `/quotes/${this.quoteId}/territory`,
        //   status: this.getStatusFor("hasTerritoryInformation"),
        //   key: "hasValidRiskAddress"
        // },
        {
          id: 3,
          name: "Property Rating",
          description: "",
          href: `/quotes/${this.quoteId}/property-rating`,
          status: this.getStatusFor("canRateQuote"),
          key: "hasTerritoryInformation"
        },
        {
          id: 4,
          name: "Select a Rate",
          description: "",
          href: `/quotes/${this.quoteId}/property-ratings`,
          status: this.getStatusFor("hasSelectedRating"),
          key: "canRateQuote"
        },
        {
          id: 5,
          name: "Underwriter Qualification",
          description: "",
          href: `/quotes/${this.quoteId}/underwriting-qualifications`,
          status: this.getStatusFor("hasUnderwritingResponses"),
          key: "hasSelectedRating"
        },
        {
          id: 6,
          name: "Applicant Details",
          description: "",
          href: `/quotes/${this.quoteId}/rating-application`,
          status: this.getStatusFor("hasCreditRating"),
          key: "hasUnderwritingResponses"
        },
        {
          id: 7,
          name: "Loss Report",
          description: "",
          href: `/quotes/${this.quoteId}/loss-report`,
          status: this.getStatusFor("hasValidLossInformation"),
          key: "hasCreditRating"
        },
        {
          id: 8,
          name: "Remarks & Mortgages",
          description: "",
          href: `/quotes/${this.quoteId}/remarks-and-mortgages`,
          status: this.getStatusFor("hasPolicyNumber"),
          key: "hasValidLossInformation"
        }
      ];

      return steps;
    }
  }
});
