
import Vue from "vue";
import { Skeleton } from "element-ui";
import { quoteMapActions } from "@/store/modules/quote";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { authMapGetters } from "@/store/modules/auth";
export default Vue.extend({
  name: "rating-viewer-skeleton",
  components: {
    Skeleton,
    CustomAlert
  },
  props: {
    rating: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      expanded: false,
      error: ""
    };
  },
  methods: {
    ...quoteMapActions(["getSendingOrReceivingXML"]),
    toggleExpansion() {
      this.expanded = !this.expanded;
    },
    async sendingXMLHandler(responseType: "VS" | "VR") {
      // this.$router.push(`/quotes/xml/${this.rating._id}/${responseType}`);
      await this.getXMLHandler(this.rating._id, responseType);
    },
    async getXMLHandler(ratingId: string, responseType: "VS" | "VR") {
      if (!ratingId || !responseType) {
        this.error = "No rating ID OR response type";
        return;
      }
      try {
        const response = await this.getSendingOrReceivingXML({
          rateId: ratingId,
          responseType: responseType as any
        });
        const { rawXML } = response.data;
        if (rawXML) {
          let blob = new Blob([rawXML], { type: "text/xml" });
          let url = URL.createObjectURL(blob);
          window.open(url);
          URL.revokeObjectURL(url);
        }
      } catch (error) {
        this.error = error.message as any;
      }
    }
  },
  computed: {
    ...authMapGetters(["isCurrentUserAdmin"]),
    faIcon(): string {
      return this.expanded ? "chevron-up" : "chevron-right";
    }
  }
});
